erpnext.ProductGrid = class {
	/* Options:
		- items: Items
		- settings: E Commerce Settings
		- products_section: Products Wrapper
		- preference: If preference is not grid view, render but hide
	*/
	constructor(options) {
		Object.assign(this, options);

		if (this.preference !== "Grid View") {
			this.products_section.addClass("hidden");
		}

		this.products_section.empty();
		this.make();
	}

	make() {
		let me = this;
		let html = ``;
		frappe.call({
			method: "erpnext.templates.pages.product_search.get_place_holder",
			args: {},
			async: false,
			callback: (data) => {
				for (const item of this.items) {
					// if (item.formatted_price) {
						let title = item.web_item_name || item.item_name || item.item_code || "";
						title = title.length > 90 ? title.substr(0, 90) + "..." : title;

						html += `<div class="col-sm-6 col-6 col-md-4 col-lg-3 item-card p-1" style="height: fit-content !important;"><div class="card text-left">`;
						html += me.get_image_html(item, title, data.message.place_holder);
						html += me.get_card_body_html(item, title, me.settings);
						html += `</div></div>`;
					}
				// }

				let $product_wrapper = this.products_section;
				$product_wrapper.append(html);
			}
		});

		// this.items.forEach((item) => {
		// 	let title = item.web_item_name || item.item_name || item.item_code || "";
		// 	title = title.length > 90 ? title.substr(0, 90) + "..." : title;

		// 	html += `<div class="col-sm-6 col-12 col-md-4 col-lg-3 item-card" style="height: fit-content !important;"><div class="card text-left">`;
		// 	html += me.get_image_html(item, title);
		// 	html += me.get_card_body_html(item, title, me.settings);
		// 	html += `</div></div>`;
		// });

		// let $product_wrapper = this.products_section;
		// $product_wrapper.append(html);
	}

	get_image_html(item, title, place_holder) {
		let image = item.website_image;

		if (image) {
			return `
				<div class="card-img-container">
					<a href="/${item.route || "#"}" style="text-decoration: none;">
						<img class="card-img" src="${image}" alt="${title}">
					</a>
				</div>
			`;
		} else {
			return `
				<div class="card-img-container">
						<a class="card-placeholder-link" href="/${item.route || '#'}" style="text-decoration: none;">
							<img class="card-img placeholder-img" src="${place_holder}" alt="${title}">
						</a>
				</div>
			`;
		}
	}

	get_card_body_html(item, title, settings) {		
		let body_html = `
			<div class="card-body text-left card-body-flex" style="width:100%">
				<div style="margin-top: 1rem; display: flex;">
		`;
		body_html += this.get_title(item, title);
		
		// get floating elements
		if (!item.has_variants) {
			if (settings.enable_wishlist) {
				body_html += this.get_wishlist_icon(item);
			}
			if (settings.enabled) {
				body_html += this.get_cart_indicator(item);
			}
		}
		
		body_html += `</div>`;
		body_html += this.get_original_name(item);
		body_html += `<div class="product-category">${item.item_group || ""}</div>`;

		// if (item.formatted_price) {
			body_html += this.get_price_html(item, settings);
		// }
		// if(item.variants){
		// }
		body_html += this.get_variants(item)
		// if(item.gift){
		// 	body_html += this.get_gift(item)
		// }
		if(item.engrave){
			body_html += this.get_engrave(item)
		}
		else{
			body_html += `
			<span class="out-of-stock mb-2 mt-1" style="color:black ;text-align: center; font-weight: 700; padding: 2px 8px; font-size: 12px; margin-top: 10px;min-height:26px;">

					</span>
			`

		}

		body_html += this.get_stock_availability(item, settings);
		body_html += this.get_primary_button(item, settings);
		body_html += `</div>`; // close div on line 49

		return body_html;
	}
	get_original_name(item){
		// if(item.original_name){

		// 	const maxLength = 35;
		// 	const text = item.original_name;
		// 	if (text.length > maxLength) {
		// 		item.original_name = text.substring(0, maxLength) + '...';
		// 	}
		// }
		


		let original_name_html = `
		<div style="direction: rtl !important; font-size: 11px !important; font-weight:500">
			<a href="/${item.route || "#"}" ">
				<div class="product-original-name" style="min-height: 20px;display: flex; align-items: center;">
					${item.original_name || ""}
				</div>
			</a>
		</div>
		`;
		return original_name_html;

	}
	get_title(item, title) {
		let title_html = `
			<a href="/${item.route || "#"}">
				<div class="product-title">
					${title || ""}
				</div>
			</a>
		`;
		return title_html;
	}

	get_wishlist_icon(item) {
		let icon_class = item.wished ? "wished" : "not-wished";
		return `
			<div class="like-action ${item.wished ? "like-action-wished" : ""}"
				data-item-code="${item.item_code}">
				<i class="bi bi-heart-fill"></i>
			</div>
		`;
	}

	get_cart_indicator(item) {
		return `
			<div class="cart-indicator ${item.in_cart ? "" : "hidden"}" data-item-code="${item.item_code}">
				1
			</div>
		`;
	}

	get_price_html(item, settings) {
		let price_html = ``

		if (item.show_price && settings.show_price) {
			price_html += `
				<div class="product-price">
				
					${item.formatted_price.replace("تومان ", "") + " تومان"}
	
			`;
	
			if (item.formatted_mrp) {
				price_html += `
					<small class="striked-price">
						<s>${item.formatted_mrp ? item.formatted_mrp.replace("تومان ", "") + " تومان": "" }</s>
					</small>
					<small class="ml-1 product-info-green">
						${item.discount} تخفیف
					</small>
				`;
			}
			price_html += `</div>`;

		} else {
			price_html += `<div class="product-price">
				<span>${settings.priceless_item_text ? settings.priceless_item_text : "تماس بگیرید"}</span>
			</div>`;
		}
		return price_html;
	}

	get_engrave(itme){
		return`
					<span class="out-of-stock mb-2 mt-1" style="color:black ;text-align: center; font-weight: 700; padding: 2px 8px; font-size: 12px; margin-top: 10px; border-top: 1px solid black; border-bottom: 1px solid black;">
						امکان حکاکی روی قلم
					</span>
				`;

	}

	// get_gift(item){

	// 	return`
	// 				<span class="out-of-stock mb-2 mt-1" style="color:black ;text-align: center; font-weight: 700; padding: 2px 8px; font-size: 12px; margin-top: 10px; border-top: 1px solid black; border-bottom: 1px solid black;">
	// 					${__("Gift")}
	// 				</span>
	// 			`;

	// }

	get_variants(item){

		var html = "";
		var new_html = "";

		if (item.variants){
			var routes = []
			item.variants.forEach(function(d) {
				if(d.all_colors){
					d.all_colors.forEach(function (data) {
						if(routes.indexOf(data.object_route) === -1){
							routes.push(data.object_route)
							html += `<a style="padding: 1px; display: flex; border-radius: 50%;width:fit-content !important; margin: 1px; border-width: 0px; border-style: solid; border-color:${data.color};"
										href="${data.object_route}"
										onmouseover="this.style.borderWidth='1px'; this.style.margin='0';"
										onmouseout="this.style.borderWidth='0px'; this.style.margin='1px';">
										<span style="background-color:${data.color};width: 16px; height: 16px; border-radius: 50%; padding: 4px; cursor: pointer;">
										</span>
									</a>`;
						}

					})
					
				}

			});
			
		}

		new_html += `<div class="d-flex justify-content-end">${html}</div>`
		
		let attrHtml = '';
		if (item.variants){

			item.variants.forEach(function(d){
	
				Object.keys(d).forEach(function (key) {
					if (key !== 'web_name' && key !== 'Colour' && key !== 'all_colors') {
						d[key].forEach(function (data) {
							attrHtml += `<a class="attr-pill-value" style="background-color: #cccccc83; width: fit-content; padding: 4px 8px; border-radius: 50px; color: gray; font-size: 10px;" href="${data.object_route}">
								<div>${data.value}</div>
							</a>`;
						});
			
						// Append each attribute's section to the main HTML string
					}
				});
			})
			new_html += `
					 <div class="d-flex flex-wrap" style="gap: 8px;">${attrHtml}</div>`;
		}
	

		
		var colorRow = `<div class="d-flex flex-column" style="gap:4px; justify-content: end; padding: 0 4px; margin-bottom: 10px;">
			${new_html}
		</div>`;




		var color_and_gift = ""

		if (item.gift){

			color_and_gift += `<div class="d-flex justify-content-between align-items-center"><div><p style="color: black; text-align: center; font-weight: 700;margin-bottom: 10px !important; padding-left: 10px;"><i class="bi bi-gift-fill"></i></p></div> ${colorRow} </div>`

		}else{
			color_and_gift += `<div class="d-flex justify-content-end align-items-center" style="min-height:26px !important">${colorRow} </div>`
		}
		




		return color_and_gift;
	}

	get_stock_availability(item, settings) {
		if (settings.show_stock_availability && !item.has_variants) {
			if (item.on_backorder) {
				return`
					<span class="out-of-stock mb-2 mt-1" style="color: var(--primary-color)">
						${__("Available on backorder")}
					</span>
				`;
				// return `
				// 	<span class="out-of-stock mb-2 mt-1" style="color: var(--primary-color)">
				// 		 ${__("Available on backorder")}
				// 	</span>
				// `;
			} else if (!item.in_stock && !item.allow_seller) {
				return `
					<span class="out-of-stock mb-2 mt-1">
						در انبار موجود نمی باشد
					</span>
				`;
				// return `
				// 	<span class="out-of-stock mb-2 mt-1">
				// 		${__("Out of stock")}
				// 	</span>
				// `;
			} else if (item.allow_seller) {
				if (settings.seller_description) {

					return `
						<span class="out-of-stock mb-2 mt-1" style="color: green !important">
							${__(settings.seller_description)}
						</span>
					`;
				}

			}
		}

		return `
						<span class="out-of-stock mb-2 mt-1" style="color: green !important">
							${"موجود در انبار"}
						</span>
					`;

		// return ``;‍‍
	}

	get_primary_button(item, settings) {

		if (item.has_variants) {
			return `
				<a href="/${item.route || '#'}">
					<div class="btn btn-sm btn-explore-variants w-100 mt-4">
						${'بررسی'}
					</div>
				</a>
			`;
		} else if (settings.enabled && item.show_price && settings.show_price && (settings.allow_items_not_in_stock || item.in_stock || item.allow_seller)) {
			// return `
			// 	<div id="${item.name}" class="btn
			// 		btn-sm btn-primary btn-add-to-cart-list
			// 		w-100 mt-2 ${item.in_cart ? 'hidden' : ''}"
			// 		data-item-code="${item.item_code}">
			// 		<span class="mr-2">
			// 			<svg class="icon icon-md">
			// 				<use href="#icon-assets"></use>
			// 			</svg>
			// 		</span>
			// 		${settings.enable_checkout ? __('Add to Cart') : __('Add to Quote')}
			// 	</div>

			// 	<a href="/cart">
			// 		<div id="${item.name}" class="btn
			// 			btn-sm btn-primary btn-add-to-cart-list
			// 			w-100 mt-4 go-to-cart-grid
			// 			${item.in_cart ? '' : 'hidden'}"
			// 			data-item-code="${item.item_code}">
			// 			${settings.enable_checkout ? __('Go to Cart') : __('Go to Quote')}
			// 		</div>
			// 	</a>
			// `;
			return `
				<div id="${item.name}" class="btn
					btn-sm btn-primary btn-add-to-cart-list
					w-100 mt-2 ${item.in_cart ? 'hidden' : ''}"
					data-item-code="${item.item_code}">
					<span class="mr-2">
						<svg class="icon icon-md">
							<use href="#icon-assets"></use>
						</svg>
					</span>
					${settings.enable_checkout ? 'اضافه کردن به سبد خرید' : 'اضافه کردن به پیش سفارش'}
				</div>

				<a href="/cart">
					<div id="${item.name}" class="btn
						btn-sm btn-primary btn-add-to-cart-list
						w-100 mt-4 go-to-cart-grid
						${item.in_cart ? '' : 'hidden'}"
						data-item-code="${item.item_code}">
						${settings.enable_checkout ? 'رفتن به سبد خرید' : 'رفتن به پیش سفارش'}
					</div>
				</a>
			`;
		} else if (!item.show_price || !settings.show_price) {
			return `
				<div id="${item.name}" 
					class="btn btn-sm w-100 ${item.in_cart ? 'hidden' : ''}" 
					data-item-code="${item.item_code}" 
					style="pointer-events: none; opacity: 0.5; cursor: not-allowed;">
					<span class="mr-2">
						<svg class="icon icon-md"></svg>
					</span>
				</div>

				`
		} else {
			return ``;
		}
	}
};
