erpnext.ProductList = class {
	/* Options:
		- items: Items
		- settings: E Commerce Settings
		- products_section: Products Wrapper
		- preference: If preference is not list view, render but hide
	*/
	constructor(options) {
		Object.assign(this, options);

		if (this.preference !== "List View") {
			this.products_section.addClass("hidden");
		}

		this.products_section.empty();
		this.make();
	}

	make() {
		let me = this;
		let html = `<br><br>`;

		let urlParams = new URLSearchParams(window.location.search);
		let param = urlParams.get("name");

		if (param) {
			frappe.call({
				method: "erpnext.templates.pages.product_search.search",
				args: {
					query: param,
					filter: urlParams.get("field_filters"),
				},
				async: false,
				callback: (data) => {
					let product_results = null,
						category_results = null;

					// Populate product results
					product_results = data.message
						? data.message.product_results
						: null;
					category_results = data.message
						? data.message.category_results_results
						: null;
					// console.log("results", product_results);
					this.items = product_results;

					this.render_products(
						product_results,
						data.message.place_holder
					);
				},
			});
		} else {
			frappe.call({
				method: "erpnext.templates.pages.product_search.get_place_holder",
				args: {},
				async: false,
				callback: async (data) => {
					this.render_products(this.items, data.message.place_holder);
				},
			});

			// console.log("items", this.items);
			// this.render_products(this.items);
		}
	}

	render_products(items, place_holder) {
		let html = ``;

		items.forEach((item) => {
			let title =
				item.web_item_name || item.item_name || item.item_code || "";
			title = title.length > 200 ? title.substr(0, 200) + "..." : title;

			html += `<div class='row list-row w-100 mb-4'>`;
			html += this.get_image_html(
				item,
				title,
				this.settings,
				place_holder
			);
			html += this.get_row_body_html(item, title, this.settings);
			html += `</div>`;
		});

		let $product_wrapper = this.products_section;
		$product_wrapper.append(html);
	}

	get_image_html(item, title, settings, place_holder) {
		let image = item.website_image;
		let wishlist_enabled = !item.has_variants && settings.enable_wishlist;
		let image_html = ``;

		if (image) {
			image_html += `
				<div class="col-md-2 col-5 border text-center rounded list-image">
					<a class="product-link product-list-link" href="/${item.route || "#"}">
						<img itemprop="image" class="website-image h-100 w-100" alt="${title}"
							src="${image}">
					</a>
					${wishlist_enabled ? this.get_wishlist_icon(item) : ""}
				</div>
			`;
		} else {
			image_html += `
					<div class="col-md-2 col-5 border text-center rounded list-image">
						<a class="product-link product-list-link" href="/${item.route || "#"}">
							<img itemprop="image" class="website-image h-100 w-100" alt="${title}"
								src="${place_holder}">
						</a>
						${wishlist_enabled ? this.get_wishlist_icon(item) : ""}
					</div>
				`;
		}

		return image_html;
	}

	get_row_body_html(item, title, settings) {
		let body_html = `<div class='col-md-10 col-7 text-right'>`;
		body_html += this.get_title_html(item, title, settings);
		body_html += this.get_item_details(item, settings);
		body_html += `</div>`;
		return body_html;
	}

	get_title_html(item, title, settings) {
		let title_html = `<div class="col-12 flex-wrap p-0" style="display: flex; justify-content: flex-end;">`;
		title_html += `
			<div class="col-md-8 col-12" style="margin-right: -15px; text-align: right !important;">
				<a class="" href="/${item.route || "#"}"
					style="color: var(--gray-800); font-weight: 500;">
					${title}
				</a>
			</div>
		`;

		if (settings.enabled) {
			title_html += `<div style="align-items: center; display: flex; justify-content: flex-end; padding-right: 0;" class="col-md-4 col-12 py-2 cart-action-container ${
				item.in_cart ? "d-flex" : ""
			}">`;
			title_html += this.get_primary_button(item, settings);
			title_html += `</div>`;
		}
		title_html += `</div>`;

		return title_html;
	}

	get_item_details(item, settings) {
		let details = `
			<p class="product-code">
				${item.item_group} | کد کالا : ${item.item_code}
			</p>
			<div class="mt-2" style="color: var(--gray-600) !important; font-size: 13px;">
				${item.short_description || ""}
			</div>
			<div class="product-price">
				${item.formatted_price || ""}
		`;
		// let details = `
		// 	<p class="product-code">
		// 		${ item.item_group } | Item Code : ${ item.item_code }
		// 	</p>
		// 	<div class="mt-2" style="color: var(--gray-600) !important; font-size: 13px;">
		// 		${ item.short_description || '' }
		// 	</div>
		// 	<div class="product-price">
		// 		${ item.formatted_price || '' }
		// `;

		if (item.formatted_mrp) {
			details += `
				<small class="striked-price">
					<s>${item.formatted_mrp ? item.formatted_mrp.replace(/ +/g, "") : ""}</s>
				</small>
				<small class="ml-1 product-info-green">
					${item.discount} OFF
				</small>
			`;
		}

		details += this.get_stock_availability(item, settings);
		details += `</div>`;

		return details;
	}

	get_stock_availability(item, settings) {
		if (settings.show_stock_availability && !item.has_variants) {
			if (item.in_stock) {
				return `
					<br>
					<span class="out-of-stock mt-2" style="color: green">
						${"موجود در انبار"}
					</span>
				`;
			} else if (!item.in_stock && !item.allow_seller) {
				// return `
				// 	<br>
				// 	<span class="out-of-stock mt-2">${ __("Out of stock") }</span>
				// `;
				return `
					<br>
					<span class="out-of-stock mt-2">${"در انبار موجود نمی باشد"}</span>
				`;
			} else if (item.allow_seller) {
				if (settings.seller_description) {
					return `
						<br>
						<span class="out-of-stock mt-2">${__(settings.seller_description)}</span>
					`;
				}
			}
		}
		return ``;
	}

	get_wishlist_icon(item) {
		let icon_class = item.wished ? "wished" : "not-wished";

		return `
			<div class="like-action-list ${item.wished ? "like-action-wished" : ""}"
				data-item-code="${item.item_code}">
				<svg class="icon sm">
					<use class="${icon_class} wish-icon" href="#icon-heart"></use>
				</svg>
			</div>
		`;
	}

	get_primary_button(item, settings) {
		let allow_seller = false;

		if (item.has_variants) {
			return `
				<a href="/${item.route || "#"}">
					<div class="btn btn-sm btn-explore-variants w-100 mt-4">
						${"بررسی"}
					</div>
				</a>
			`;
			// return `
			// 	<a href="/${ item.route || '#' }">
			// 		<div class="btn btn-sm btn-explore-variants w-100 mt-4">
			// 			${ __('Explore') }
			// 		</div>
			// 	</a>
			// `;
		} else if (
			settings.enabled && item.show_price && settings.show_price &&
			(settings.allow_items_not_in_stock ||
				item.in_stock ||
				item.allow_seller)
		) {
			return `
				<div id="${item.name}" class="btn
					btn-sm btn-primary btn-add-to-cart-list mb-0
					${item.in_cart ? "hidden" : ""}"
					data-item-code="${item.item_code}"
					style="margin-top: 0px !important; max-height: 30px; float: right;
						padding: 0.25rem 1rem; min-width: 135px;">
					<span class="mr-2">
						<svg class="icon icon-md">
							<use href="#icon-assets"></use>
						</svg>
					</span>
					${
						settings.enable_checkout
							? "اضافه کردن به سبد خرید"
							: "اضافه کردن به پیش خرید"
					}
				</div>

				<div class="cart-indicator mx-2 list-indicator  ${
					item.in_cart ? "" : "hidden"
				}">
					1
				</div>

				<a href="/cart">
					<div id="${item.name}" class="btn
						btn-sm btn-primary btn-add-to-cart-list
						ml-4 go-to-cart mb-0 mt-0
						${item.in_cart ? "" : "hidden"}"
						data-item-code="${item.item_code}"
						style="padding: 0.25rem 1rem; min-width: 135px;">
						${settings.enable_checkout ? "رفتن به سبد خرید" : "رفتن به پیش سفارش"}
					</div>
				</a>
			`;

			// return `
			// 	<div id="${ item.name }" class="btn
			// 		btn-sm btn-primary btn-add-to-cart-list mb-0
			// 		${ item.in_cart ? 'hidden' : '' }"
			// 		data-item-code="${ item.item_code }"
			// 		style="margin-top: 0px !important; max-height: 30px; float: right;
			// 			padding: 0.25rem 1rem; min-width: 135px;">
			// 		<span class="mr-2">
			// 			<svg class="icon icon-md">
			// 				<use href="#icon-assets"></use>
			// 			</svg>
			// 		</span>
			// 		${ settings.enable_checkout ? __('Add to Cart') :  __('Add to Quote') }
			// 	</div>

			// 	<div class="cart-indicator mx-2 list-indicator  ${item.in_cart ? '' : 'hidden'}">
			// 		1
			// 	</div>

			// 	<a href="/cart">
			// 		<div id="${ item.name }" class="btn
			// 			btn-sm btn-primary btn-add-to-cart-list
			// 			ml-4 go-to-cart mb-0 mt-0
			// 			${ item.in_cart ? '' : 'hidden' }"
			// 			data-item-code="${ item.item_code }"
			// 			style="padding: 0.25rem 1rem; min-width: 135px;">
			// 			${ settings.enable_checkout ? __('Go to Cart') :  __('Go to Quote') }
			// 		</div>
			// 	</a>
			// `;
		} else {
			return ``;
		}
	}
};
